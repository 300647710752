@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700&display=swap')

@import 'variables'
@import 'mixins'
@import 'animation'

//------------------------------------------

body
  background-color: #fff
  font-family: $font-body
  line-height: 1.2
  color: rgba($color, .8)
  text-align: center
  -webkit-text-size-adjust: none
  text-size-adjust: none

h1, h2, h3, h4, h5, h6
  font-family: $font-display

h1
  font-weight: 400
  font-size: 2.5rem

h2
  font-weight: 600
  font-size: 1.875rem
  margin-bottom: 1rem
  @include transition($hover)

  span
    cursor: default
    color: $color-accent
    font-weight: 500
    display: inline-block
    margin: 0 -6px 0 2px
    @include transition($hover)

  &:hover
    span
      transform: translateY(3px)

p
  font-size: 1rem
  font-weight: 300
  line-height: 1.5
  margin-bottom: 1rem

a
  color: rgba($color, .85)
  text-decoration: underline
  @include transition($hover)

  &:hover
    color: $color-accent

strong
  font-weight: 600

.light
  color: $color-light

header
  background-color: rgba($color, .85)
  padding: 2rem
  @include flex
  justify-content: space-between
  align-items: center

  @media screen and (max-width: $width-sm)
    padding: 1rem

    img
      width: 85%
      float: left

  img
    cursor: pointer

  nav
    font-family: $font-display
    font-weight: 500

    ul
      display: flex
      gap: 1.5rem

    li
      display: inline

      a
        text-decoration: none

main
  position: relative

section
  margin: 0 auto
  padding: 10rem 2rem 0

  @media screen and (max-width: $width-sm)
    padding-top: 8rem

section.top
  background-color: rgba($color, .85)
  color: $color-light
  padding: 6rem 2rem 10rem
  margin: 0

  h1
    max-width: 480px
    margin: 0 auto 6rem

    @media screen and (max-width: $width-sm)
      font-size: 2rem
      line-height: 1.3
      width: 90%

  p
    font-family: $font-display
    font-size: 1.25rem
    letter-spacing: .01rem
    margin-bottom: 0

    &.hi
      font-size: 5rem
      font-weight: 600
      animation: $animate

  @media screen and (max-width: $width-sm)
    padding: 8rem 1rem 9rem

    p.hi
      font-size: 4.2rem

section.about, section.side-projects
  max-width: 625px

section.side-projects

  div
    margin-top: 3.5rem

  a
    font-family: $font-display
    font-size: 1.3rem
    font-weight: 500
    margin-bottom: 0.5rem

  p
    margin-top: .5rem
    margin-bottom: 0

footer
  padding-top: 9rem
  margin: 0 auto 2rem
  font-family: $font-display
  @include flex
  align-items: baseline
  justify-content: center
  gap: 1rem

  span
    color: $color-accent

  p, a
    font-size: 0.875rem

  a
    font-weight: 500
