// Use on h1 on page load
@keyframes hop
  0%
    transform: translateY(0px)
  
  30% 
    transform: translateY(-6px)

  60% 
    transform: translateY(3px)
  
  100% 
    transform: translateY(0px)