// Use on hovers
@mixin transition($property)
  -webkit-transition: $property
  -ms-transition: $property
  transition: $property

// display: flex
@mixin flex
  display: -webkit-box
  display: -ms-flexbox
  display: flex