// Colors
$color: black
$color-light: #EEECEC 
$color-accent: red

// Typography
$font-body: 'Open Sans', sans-serif
$font-display: 'Bitter', serif

// Transitions
$hover: all .2s ease-in-out
$animate: hop .5s ease-in-out .7s 1

// Breakpoints
$width-md: 992px
$width-sm: 640px
$width-xs: 374px